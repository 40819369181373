<template>
  <!-- 弹窗组件 -->
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 1000"
        @click.stop="hide"
      ></div>
      <div class="mask-company animated faster fadeIn">
        <div class="row-end">
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="user-protocol">
          <div>干纺织增值服务协议</div>
          <h2>一、前言</h2>
		  欢迎您使用干纺织增值服务！<br/>
		  为使用干纺织增值服务（简称“本服务”），您（简称“您”或“用户”）应当阅读并遵守干纺织《增值服务协议》（简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。
		  除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您购买或使用干纺织软件内的任何收费服务，即视为您已阅读并同意本协议的约束。
		  
		  <h2>二、协议条款的确认和接受</h2>
          2.1 条款的接受<br/>
          在用户决定使用相关增值服务之前，请仔细阅读本收费协议条款。用户必须在完全同意以下条款的前提下，才能进行购买程序。干纺织将根据以下收费协议条款为用户提供增值服务。用户在享受干纺织增值服务时必须完全、严格遵守本协议条款。
          2.2 协议适用主体范围<br/>
          干纺织提供的网络服务采用收费与免费结合的方式，用户须先成为干纺织普通注册用户之后，才能继续进⾏购买服务。通过购买程序支付费用后，用户就可以轻松享受干纺织增值服务（招聘用户需要完成企业认证方可购买招聘方面的增值服务）。在进⼊购买程序之前，请用户首先同意并接受以下服务条款。
          2.3 本协议内容<br/>
          本协议为您与干纺织《用户服务协议》的补充协议，是其不可分割的组成部分，与其构成统⼀整体。本收费协议中未约定事项以干纺织《用户服务协议》中的约定为准。本协议与前述协议存在冲突的，以本协议为准。
          本协议内容同时包括干纺织可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容⼀经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。
          2.4 条款的更新<br/>
          干纺织有权根据互联网的发展和中华人民共和国有关法律、法规的变化，不断地完善服务质量并依此修改服务条款。干纺织有权就本协议随时更新，并在软件内或通过您留下的联系方式通知您。用户的权利以及义务的表述、购买程序及收费标准，均以最新的服务条款为准。用户如果不同意该条款的修改并不影响已发生责任及义务的承担；如果用户在条款变更后继续访问或使用干纺织，则视为用户已经接受对本协议的修改。
          
		  <h2>三、服务说明</h2>
          3.1 增值服务，指按照干纺织的指定方式支付⼀定服务费用之后，用户可以享有由干纺织或第三方提供的在干纺织平台虚拟道具、招聘/求职方面特权等服务（以下简称“本服务”）。
          <br/>3.2 干纺织可能会根据不同的产品及服务类型，推出不同的增值服务类型。目前，干纺织提供定的增值服务包括VIP会员、职位刷新、职位置顶、职位急招、人才推荐等。
          <br/>3.3 干纺织提供“纺职豆”做为平台内用于消费的通用兑换产品，可用于刷新、置顶、急招、人才推荐等招聘工具。
          <br/>3.3.1通常情况下，1元人民币可购买1个纺职豆，具体金额以购买页面展示金额为准。
          <br/>3.3.2纺职豆属于在线交付的充值类商品，用户一旦充值成功，充值即确定完成，干纺织不提供任何转售、交换、再购买纺职豆或逆向兑换服务。纺职豆兑换刷新、置顶、急招、人才推荐等招聘工具后，不可撤销，请您在充值和兑换时谨慎确认。
          <br/>3.4 干纺织可能会根据用户及市场的需求、产品及服务类型的变化等各种因素，对现有增值服务种类、功能、价格、权益进行调整以及不断推出新的增值服务种类。具体增值服务种类及服务内容及费用标准以相关服务页面公布、实际提供的内容为准。用户可以自行根据需要选择相应服务。
          <br/>3.5 用户可通过登录干纺织，免费查询其消费的服务的详情（包括其购买的增值服务名称、购买时间、标示价格、即时服务状态等）。
          <br/>3.6 干纺织对外售卖的所有道具，包括但不限于VIP会员、职位刷新、职位置顶、职位急招、人才推荐等，均为辅助性⼯具，不构成对招聘效果的任何保证或担保。
          <br/>3.7 用户理解并同意，干纺织有权基于交易安全、运营策略、行业惯例等方面的考虑设定涉及交易的相关限制和提醒事项，包括但不限于交易时间、交易限额、交易次数等。
		
          <h2>四、收费标准及服务开通</h2>
          4.1 本服务是干纺织提供的增值服务，用户须在按照本服务的收费标准支付相应费用后，方可使用本服务。
          <br/>4.2 收费费用标准：服务费⽤的具体资费政策以干纺织软件内届时公布的内容为准，干纺织有权随时修改本服务的资费政策（包括但不限于服务价格）、订购方式，并在干纺织软件内予以公布。
          <br/>4.3 如用户不同意按照届时干纺织制定（修改）并公布的资费政策（包括但不限于服务价格）支付服务费用或不同意按照届时干纺织制定（修改）并公布的订购方式订购本服务，用户将不能获得本服务任何部分或全部，如已获得本服务用户应自行主动取消本服务，但干纺织有权不予退还用户已支付的任何服务费用。但用户已经付费取得的服务内容、服务时⻓等，可以在原有范围内使用，如因服务内容变更导致无法继续使用的，用户可以申请退还未使用部分的费用。
          <br/>4.4 服务开通
          <br/>重要约定：用户通过网银微信支付、手机支付宝支付等，用户充值一旦成功，充值即确定完成，干纺织将不提供任何更改、修正服务。用户不得向干纺织要求退还充值金额，除非所充⼊纺职豆本身无法兑换到任何服务中，若发生异议用户可通过客服专线查询处理。
          <br/>4.5 违约行为
          <br/>非经干纺织许可，用户不得通过以下⽅式开通/获取本服务，否则将构成违约；
          <br/>以营利、经营等非个人使用的目的为自己或他⼈开通本服务；
          <br/>通过任何机器⼈软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他⼈开通本服务；
          <br/>通过任何不正当手段或以违反诚实信用原则的方式（包括但不限于利用平台可能存在的漏洞、绕开平台的商业规则/技术规则等）为自己或他⼈开通/获取本服务；
          <br/>通过非干纺织指定的⽅式为自己或他⼈开通本服务；
          <br/>通过侵犯干纺织或他⼈合法权益的⽅式为自己或他⼈开通本服务，如通过第三方平台由他⼈代充代付；
          <br/>通过其他违反干纺织《用户协议》《职位信息发布规则》或相关法律、行政法规、国家政策等的方式为自己或他⼈开通/获取本服务。
          <br/>4.6 服务期限
          <br/>本服务中相关道具可能存在相关期限，如有期限限制以用户自行选择并支付相应服务费⽤的期限为准，用户也可以登录【账户资产】-【资产信息】页面进行查询。
          <br/>用户知悉，用户行为(包括但不限于购买/使用/兑换产品、注销账号等)可能影响本服务中相关产品或道具的有效期，例如，注销未成功的用户，在注销申请期间（冷静期内）已生效的增值服务将继续生效，有效期不予暂停。
          <br/>4.7 风险提示
          <br/>用户在使用干纺织纺职豆充值系统时，用户必须仔细确认自己的帐号及充值纺职豆，若因为用户自身输⼊账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值数量等情形而损而害自身权益，不得因此要求干纺织作任何补偿或赔偿。
          <br/>若用户以不正当的方式、非干纺织所指定的充值方式进行充值，干纺织不保证该充值顺利或者正确完成。若因此造成用户权益受损时，干纺织不会作任何补偿或赔偿，干纺织同时保留随时冻结或终止该用户账号资格及使用各项充值服务的权利。
          <br/>4.8 费用退还
          <br/>干纺织平台提供的增值服务均为数字化商品，不支持七天无理由退款。相关服务（含纺职豆）⼀经购买/使用/兑换，不可转让、退款、提现等（但因干纺织提供的增值服务存在质量瑕疵致使用户无法使用平台服务的、本协议另有约定、法律法规要求必须退款的或经干纺织判断后认为可以退款的除外，相关流程可咨询人工客服人工。
          <br/>4.9 申请开票
          <br/>经干纺织平台付费购买工具、直接充值后，用户可以到【账户资产】-【订单管理】中申请开具发票。
          <br/>用户每⼀次申请开票的金额不得超过页面显示的最高可开金额。开票申请提交后，干纺织会对用户提交的申请进行处理。
          <br/>用户应保证提交给干纺织平台的开票资料真实、合法、有效；开票主体应与用户当前所认证企业保持⼀致，如用户提交的开票资料有误或开票主体不⼀致等原因，干纺织有权直接拒绝用户的开票请求。
		  <br/>用户如遇开票问题，例如更换发票、重开发票等，可咨询人工客服。
		  
		  <h2>五、用户权利及义务</h2>
          5.1 重要约定
          <br/>用户不得通过未经干纺织授权许可的任何第三方平台或渠道（如淘宝、京东等平台上的店铺）购买纺职豆等干纺织提供的增值服务或产品；不得将账号提供给第三方进行代充代付。因违反前述约定导致用户隐私泄露的，用户应当自行承担相应后果；如给干纺织造成损失的，干纺织可以要求用户承担违约责任并赔偿损失。
          <br/>5.2 违约情形
          <br/>用户同意不会采⽤收费或免费的⽅式，全部或部分地在任何公开场合展示用户购买的增值服务内容，除⾮用户的上述⾏为不会构成侵权。
          <br/>5.3 用户在使⽤本服务时不得出现以下情况/从事以下活动，包括但不限于：
          <br/>发布、传送、传播、储存违反国家法律、危害国家安全统⼀、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；
          <br/>发布、传送、传播、储存侵害他⼈名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
          <br/>虚构事实、隐瞒真相以误导、欺骗他⼈；
          <br/>发表、传送、传播广告信息及垃圾信息；
          <br/>滥用客服功能、骚扰客服人员或平台其他⼯作⼈员；
          <br/>涉嫌从事其他违反法律法规、政策及公序良俗、社会公德等的⾏为；
          <br/>通过任何方式搜集本服务中其他用户的用户名、电⼦邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；
          <br/>通过本服务发布包含广告、宣传、促销等内容的信息。
          <br/>5.4 对自己行为负责
          <br/>用户充分了解并同意，用户必须为自己注册帐号下的一切行为负责，包括但不限于用户所发表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因使用内容⽽引起的所有风险，包括因对内容的正确性、完整性或实⽤性的依赖⽽产生的风险。
          <br/>若用户违反干纺织《用户协议》《职位信息发布规则》或本协议内容，干纺织有权在未通知的情况下随时对其账号采取⼀定的处理措施，包括但不限于部分或全部冻结终止其账号使用资格的，用户不得因此要求干纺织作任何补偿或赔偿。
          <br/>5.5 用户的权利与义务
          <br/>用户应自行负责妥善且正确地保管、使用、维护其在干纺织申请取得的账户及账户密码。用户应对其账户密码采取必要和有效的保密措施。非干纺织原因致使用户账户密码泄漏以及因用户保管、使用、维护不当造成损失的，干纺织无须承担与此有关的任何责任。
          <br/>用户明确了解并同意，本协议第2条款约定的付费方式为代收费运营商托收的付款方式，用户通过此种付费方式付费可能存在一定的商业风险（包括但不限于不法分⼦利用用户账户或银行卡等有价卡等进行违法活动），该等风险均会给用户造成相应的经济损失。为此，用户同意干纺织在充分履行其在本协议项下全部义务及勤勉谨慎义务的前提下不对用户的前述⻛险和损失承担任何责任；并且，干纺织不承担向不法分⼦追究侵权责任或者代其向用户承担损失的任何责任和义务。
		  
          <h2>六、变更及免责条款</h2>
          6.1 干纺织有权随时修改本协议的任何条款，⼀旦本协议的内容发生变动，干纺织将会在⽹站上公布修改之后的协议内容，若用户不同意上述修改，则可以选择停⽌使用干纺织服务。干纺织也可选择通过其他适当⽅式（⽐如系统通知、消息推送、平台公告）向用户通知修改内容。如果不同意干纺织对本协议相关条款所做的修改，用户有权停止使用干纺织服务。如果用户继续使用干纺织服务，则视为用户接受干纺织对本协议相关条款所做的修改。
          <br/>6.2 用户充分了解并同意，由于互联⽹服务的特殊性，干纺织可能会按照相关法规、双方约定或在其他必要时，中止或终止向用户提供本服务，届时，干纺织会依法保护用户的合法权益。用户在此同意并确认：干纺织提供服务时可能会涉及到虚拟物品（包括但不限于工具、材料等），该虚拟物品仅在服务运营期内有效，服务停止时，虚拟物品将随服务停止运营⽽消失，用户一经获得将不得以任何形式退还给干纺织。
		  
		  <h2>七、服务的中断和终止</h2>
          7.1 因发生干纺织不可抗拒的事由，如政府行为、不可抗力，导致服务无法继续，干纺织将尽快通知用户，但不承担由此对用户造成的任何损失并不退还任何费⽤。
          <br/>7.2 如用户严重违反本服务条款中的内容，干纺织有权在不通知用户的情况下立即终止用户已购买的部分或所有服务，以及暂时或永久取消用户的干纺织账户和使用权限，但不退还任何已缴纳的服务费⽤。
          <br/>7.3 重要约定：用户违反本协议第1条，通过未经干纺织授权的淘宝店铺等第三方平台或渠道进行代充代付，购买干纺织纺职豆产品或其他道具/服务等行为的，直接构成严重违约行为，干纺织有权终止用户购买的此等产品的使用权限，并不予退还相应的服务费用。
          <br/>7.4 干纺织未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单⼀或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。干纺织随时有权要求用户继续履行义务并承担相应的违约责任。
		  
		  <h2>八、违约责任</h2>
          8.1 如因干纺织违反有关法律、法规或本协议项下的任何条款而给用户造成损失，干纺织同意承担由此造成的损害赔偿责任。但干纺织的赔偿责任亦以用户就使用服务所支付干纺织的价款为限。
          <br/>8.2 用户若违反本协议或相关法令，导致干纺织、或其关系企业、受雇⼈、受托⼈、代理⼈及其它相关履行辅助⼈因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担损害赔偿责任。
          <br/>8.3 对第三方损害的处理
          <br/>用户有违反本协议任何⾏为，导致任何第三⽅损害的，用户应当独立承担责任；干纺织因此遭受损失的，用户也应当一并赔偿。
          <br/>8.4 处罚规则
          <br/>用户知悉并同意，如用户被举报、被干纺织发现或经干纺织独立判断认为用户存在/涉嫌违反国家法律法规/监管规定/政府政策、违反干纺织《用户协议》《职位信息发布规则》/本协议、扰乱本平台的运营秩序、破坏平台的招聘求职环境等行为的，干纺织有权对其采取以下一项或多项处理措施：
          <br/>如本协议对此行为有单独条款约定处理方式的，按照该条款处理；
          <br/>无需通知用户而采取一种或多种措施制止用户的行为及行为产生的后果，包括但不限于如删除/屏蔽相关链接或内容、暂时/永久性冻结用户的账号、限制账户部分或全部的使用权限等；
          <br/>无需通知用户而中断或终⽌部分或全部服务且用户已支付的费用将不予退还且不提供任何形式的补偿/赔偿；
          <br/>如用户的行为使干纺织或/及其关联公司遭受任何损失的，用户应当承担全部损失赔偿责任并在干纺织要求的时限内完成费用支付。
          <br/>8.5 特别提示
          <br/>如因用户违反本协议、被干纺织系统检测出异常操作行为的（包括但不限于短时间内多次购买/取消订单、频繁、⼤量发布新职位等）或被干纺织发现利⽤规则漏洞获取任何服务的，干纺织有权在不通知用户的情况下，按照相关规定对其采取处罚措施，包括但不限于，暂时/永久禁封用户账号、将用户加⼊⿊名单、限制用户使用部分或全部服务、拒绝为其退货/退款。
          <br/>部分用户因违反《用户协议》《职位信息发布规则》被限制使⽤的，包括但不限于禁言、限期冻结等，平台有权根据实际情况，将其所购买服务的有效期进行相应顺延或返还部分纺职豆（最终以页面显示为准）。
		  
		  <h2>九、其他条款</h2>
          9.1 本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如本服务条款与中华人民共和国法律相抵触时，则该等条款将按法律规定重新修订，而其它条款则依旧有效并具有法律效力。
          <br/>9.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向干纺织所在地的人民法院提起诉讼。
          <br/>9.3 本协议项下所有的通知均可通过软件内通知、电⼦邮件、短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。
		</div>
        <div class="row-center mask-btn">
          <div class="text-white bg-blue flex-center pointer" @click="hide">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: false,
    };
  },
  methods: {
    // 显示
    show() {
      this.status = true;
    },
    // 隐藏
    hide() {
      this.status = false;
      this.$emit("hide");
    },
  },
};
</script>
<style lang="less" scoped>
.mask-company {
  width: 1200px;
  background: #ffffff;
  border-radius: 3px;
  padding: 1rem 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1003;
  .img-title img {
    width: 21px;
    height: 19px;
    margin-right: 10px;
  }
  .mask-btn {
    justify-content: flex-end;
    div {
      padding: 6px 30px;
      margin-left: 0.875rem;
      font-size: 0.875rem;
    }
    .border-first {
      border: 1px solid #126bf9;
    }
  }
  .user-protocol {
    padding: 10px;
    width: 100%;
    height: 800px;
    line-height: 50px;
    overflow-y: auto;
    div {
      color: #126bf9;
      font-size: 18px;
    }
    div:first-child {
      text-align: center;
      color: #333;
      font-size: 28px;
    }
    p {
      color: #333;
      font-size: 24px;
    }
  }
}
</style>
